
export const gradients = [
    'linear-gradient(135deg, #43cea2 0%, #185a9d 100%)', // Green to Blue
    'linear-gradient(135deg, #8E2DE2 0%, #4A00E0 100%)', // Purple gradient
    'linear-gradient(135deg, #2193b0 0%, #6dd5ed 100%)', // Blue gradient
    'linear-gradient(135deg, #8E2DE2 0%, #6dd5ed 100%)', // Purple to Blue
    'linear-gradient(135deg, #f7971e 0%, #ffd200 100%)', // Orange to Yellow gradient
    'linear-gradient(135deg, #f12711 0%, #f5af19 100%)', // Red to Orange
  ];

  export const monthAbbreviations = {
    January: "Jan",
    February: "Feb",
    March: "Mar",
    April: "Apr",
    May: "May",
    June: "Jun",
    July: "Jul",
    August: "Aug",
    September: "Sep",
    October: "Oct",
    November: "Nov",
    December: "Dec",
  };